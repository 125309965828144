<template>
  <div :class="['level', `level-${levelCount}`]">
    <ul v-show="$slots.default">
      <slot/>
    </ul>

    <slot name="metanavigation"/>
  </div>
</template>

<script>
export default {
  name: 'MainNavigationLevel',
  props: {
    level: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      levelCount: this.level + 1
    }
  }
}
</script>

<style lang="scss">
// desktop
$level-padding-y: 0 !default;
$level-padding-x: 0 !default;

$level-1-bg: transparent !default;

$level-2-padding-y: $spacer * 2.5 !default;
$level-2-padding-x: 0 !default;
$level-2-min-height: 0 !default;
$level-2-max-height: 60vh !default;
$level-2-bg: #fbfafa !default;
$level-2-border-width: 2px !default;
$level-2-border-color: $primary !default;

$level-3-padding-y: 0 !default;
$level-3-padding-x: 0 !default;

// mobile
$level-mobile-breakpoint: $mobile-breakpoint !default;

$level-mobile-padding-y: $spacer * 1.5 !default;
$level-mobile-padding-x: $spacer * 1.5 !default;

$level-1-mobile-top-offset: $mainnavigation-mobile-height !default;
$level-1-mobile-bg: $level-2-bg !default;

$level-2-mobile-padding-y: $level-mobile-padding-y * 0.5 !default;
$level-2-mobile-padding-x: $level-mobile-padding-x !default;
$level-2-mobile-min-height: 0 !default;
$level-2-mobile-max-height: none !default;
$level-2-mobile-bg: transparent !default;
$level-2-mobile-border: 0 none !default;

$level-3-mobile-padding-y: $level-2-mobile-padding-y !default;
$level-3-mobile-padding-x: $level-2-mobile-padding-x !default;

.mainnavigation {
  .mainnavigation-menu {
    .level {
      > ul {
        @include list-unstyled();
        margin: 0;
        padding: $level-padding-y $level-padding-x;
      }

      &.level-1 {
        background-color: $level-1-bg;

        > ul {
          display: flex;
          justify-content: flex-end;
        }
      }

      &.level-2 {
        position: absolute;
        top: 100%;
        left: 50%;
        overflow: hidden;
        overflow-y: auto;
        width: 100vw;
        min-height: $level-2-min-height;
        max-height: $level-2-max-height;
        transform: translateX(-50%);
        background-color: $level-2-bg;
        border-bottom: $level-2-border-width solid $level-2-border-color;
        box-shadow: 0 10px 25px 0 rgba($yellow, 0.05);

        > ul {
          @include make-container();
          @include make-container-max-widths();
          padding: $level-2-padding-y $level-2-padding-x;
        }
      }

      &.level-3 {
        padding: $level-3-padding-y $level-3-padding-x;
      }
    }

    @include media-breakpoint-down($level-mobile-breakpoint) {
      .level {
        > ul {
          padding: $level-mobile-padding-y $level-mobile-padding-x;
        }

        &.level-1 {
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          overflow: hidden;
          overflow-y: auto;
          margin-top: $level-1-mobile-top-offset;
          background-color: $level-1-mobile-bg;
          z-index: -1;

          > ul {
            display: block;
            @include make-container();
            @include make-container-max-widths();
          }
        }

        &.level-2 {
          position: static;
          overflow: auto;
          width: auto;
          min-height: $level-2-mobile-min-height;
          max-height: $level-2-mobile-max-height;
          transform: none;
          background-color: $level-2-mobile-bg;
          border-bottom: $level-2-mobile-border;
          box-shadow: none;

          > ul {
            margin-right: auto;
            padding: $level-2-mobile-padding-y $level-2-mobile-padding-x;
            max-width: none;
          }
        }

        &.level-3 {
          > ul {
            padding: $level-3-mobile-padding-y $level-3-mobile-padding-x;
          }
        }
      }
    }
  }
}
</style>
